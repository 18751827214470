.ReactTable .-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-th:before {
  content: '▼';
  float: right;
  margin-top: 10px;
  color: #cce0f9;
}

.ReactTable .-sort-desc:before {
  content: '▼';
  float: right;
  margin-top: 10px;
  color: #90a3bc;
}

.ReactTable .-sort-asc {
  box-shadow: none !important;
}

.ReactTable .-sort-asc:before {
  content: '▲';
  float: right;
  margin-top: 10px;
  color: #90a3bc;
}
